<template>
    <div class="titlediv">
        <a id="map"></a>
        <h1 v-if="selectedUni">
            What ties does <span id="university_name">{{ selectedUni.name }} </span> have to Israel?
        </h1>
          <h1 v-else>What ties do {{contact.genericInstitutions}} have to Israel?</h1>

    </div>
    <div class="containerMain">
        <div class="map">
            <img class="mapImage" :src="require('@/country_specific/assets/NL.svg')" />
        <div>
        <div v-for="academy in academicInstitutions" :key="academy.name">

          <svg
              :id="academy.tag"
              class="dot"
              :class="{ 'selected': selectedUni?.tag === academy.tag }"
              :style="{
                    left: `${academy.dot_position.x}%`,
                    top: `${academy.dot_position.y}%`,
                    backgroundColor: academy.color
                      }"
              @click="selectUni(academy)"
              height="20" width="20" xmlns="http://www.w3.org/2000/svg">
          </svg>
        </div>
      </div>
    </div>

    <div v-if="selectedUni?.display_description" class="description-box">
      <p>{{ selectedUni.description }}</p>
    </div>

    <div class="map">
        <div class="blurb">
            <div v-if="selectedUni" class="moveToMiddle">
                <TiesList :academy="selectedUni" />
                <a href="#map"><button class="blurbBeginNoColour" type="button">Back to map</button></a>
            </div>
            <div v-else>
                <h3>Select an institution to find out more</h3>
            </div>
        </div>
    </div>
  </div>

</template>

<script>

import info from "../assets/references/ties.json";
import contact from "@/country_specific/assets/references/contactValues.json"
import {academicInstitutions} from "@/country_specific/assets/references/academic_institutions"
import TiesList from "@/core/components/TiesList.vue";
import { ref } from 'vue';

export default {
    name: "InteractiveMap",
    components: {
        TiesList,
    },
    props: {
        msg: String,
    },
    emits: ["select-uni"],
    setup() {
      const selectedUni = ref(null);
      return { selectedUni };
    },
    data() {
        return {
            currentInfo: null,
            info: info,
            academicInstitutions,
            contact
        };
    },

    methods: {

        selectUni(uni) {
            if (this.selectedUni?.tag == uni.tag) {
              //unselect uni
              this.selectedUni = null;
              this.currentInfo = null;

            } else {
              this.selectedUni = uni
            }
            this.getSlideFromID(uni.tag);
        },
        getSlideFromID(newID) {
            //TODO: change chapter if new chapter
            this.currentInfo = this.info[newID];
            //TODO: add error handler if next slide does not exist. (e.g. go to start or page not found)
            if (!this.currentInfo) {
                console.log("could not find uni");
                console.log(newID);
            }
        },
    },
    created() {
        this.$watch(
            () => this.$route.params.uni,
            (newId) => {
                this.selectUni(newId)
            }
        )
    },
};

</script>

<style scoped>

.mapImage {
    max-height: calc(100vh - 168px);
    max-width: min(calc(100vw - 30px), 600px);
    margin-bottom: 0px;
    /* margin-left: 10px;
  margin-right: 20px; */
    padding: 0pt;
    z-index: 0;
    position: relative;
    left: 0;
}

.map {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    padding: 0pt;
    flex: content;

}

.blurb {
    margin: 20px 10px;
    padding: 0;
    align-content: center;
    text-align: center;
}

.dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 2;

}

.dot:hover {
  transform: translate(-50%, -50%) scale(1.2);
  z-index: 10;
}

.dot.selected {
  transform: translate(-50%, -50%) scale(1.2);
  box-shadow: 0 0 0 4px rgba(100,100,100,0.3);
  z-index: 20;
}

.description-box {
  border : var(--c8) 1px solid;
  position: relative;
  width : 80%;
  margin-left: auto;
  margin-right: auto;
  flex: content;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  padding: 5px;
}

.description-box p {
  margin: 0;
  font-size: 12px;
  color: #666;
}


</style>
