<script setup>

</script>

<template>

  <footer>
    <p><b>Questions, comments or ideas for this database? <a class="linkDarkTheme"
                                                             href="mailto:academiccomplicity@proton.me">Contact us!</a></b></p>
    <p><b>Content</b>: <a  class="linkDarkTheme" href="https://stopwapenhandel.org/" target="_blank">Stop Wapenhandel</a> and <a class="linkDarkTheme" href="https://elsc.support/" target="_blank">European Legal Support Center</a>, supported by a team of volunteers. <b>Website
      design</b>: <a  class="linkDarkTheme" href="https://solid-sustainability.org" target="_blank">Solid Sustainability Research</a> and volunteer contributors © 2025</p>
  </footer>

</template>

<style scoped>

</style>