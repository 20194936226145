/**
 * Enum for partner categories with their associated colors.
 * @enum {string}
 */
const Categories = Object.freeze({
    UNIVERSITY: "var(--c7)",
    RESEARCH_LAB: "var(--c8)"
});

/**
 * Represents an academic institution.
 * @typedef {Object} academicInstitution
 * @property {string} name - The name of the academic institution.
 * @property {string} tag - The institution tag
 * @property {string} description - A description of the academic institution.
 * @property {boolean} display_description - Whether to display the description.
 * @property {{x: number, y: number}} dot_position - The X-coordinate of the dot.
 * @property {string} color - The color category of the academic institution.
 * @property {Object[]} groups - The militantist groups from the academy
 */

/**
 * @type {AcademicInstitution[]}
 */
export const academicInstitutions = [
    {
        name: "Erasmus Universiteit Rotterdam",
        tag: "EUR",
        description: "",
        display_description: false,
        dot_position: { x: 34.8, y: 58.2 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "Rotterdam Student Encampment",
                "link": "https://www.instagram.com/rotterdamencampment/",
                "img": "rse.png"
            },
            {
                "name": "Palestine Solidarity Rotterdam",
                "link": "https://linktr.ee/palestinesolidarityrotterdam",
                "img": "psr.jpg"
            },
            {
                "name": "Erasmus School of Colour",
                "link": "https://www.instagram.com/erasmus_school_of_colour/",
                "img": "esoc.png"
            },
            {
                "name": "Solidarity at Erasmus University College",
                "link": "https://www.instagram.com/solidarity.euc/",
                "img": "euc.png"
            }
        ]
    },
    {
        name: "Universiteit Leiden",
        tag: "LU",
        description: "",
        display_description: false,
        dot_position: { x: 33.6, y: 48.5 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "Students for Palestine",
                "link": "https://www.instagram.com/studentsforpalestine",
                "img": "s4p.png"
            },
            {
                "name": "End Fossil LU & TUD",
                "link": "https://linktr.ee/endfossil.lu.tud",
                "img": "EFLD.jpg"
            },
            {
                "name": "Leiden Scholars for Palestine",
                "link": "https://www.instagram.com/leidenscholarsforpalestine/",
                "img": "lsp.png"
            }
        ]
    },
    {
        name: "Open Universiteit",
        tag: "OU",
        description: "",
        display_description: false,
        dot_position: { x: 67.6, y: 92.7 },
        color: Categories.UNIVERSITY,
        groups : [
        ]
    },
    {
        name: "Radboud Universiteit Nijmegen",
        tag: "RUN",
        description: "",
        display_description: false,
        dot_position: { x: 65.5, y: 60 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "Nijmegen for Palestine",
                "link": "https://linktr.ee/nijmegenforpalestine",
                "img": "nfp.jpg"
            },
            {
                "name": "Radboud Staff 4 Palestine",
                "link": "https://www.instagram.com/radboudstaff4pal3stine/?hl=en",
                "img": "rsp.png"
            },
            {
                "name": "Nijmegen Student Encampment",
                "link": "https://www.instagram.com/nijmegenencampment/?hl=en",
                "img": "nse.png"
            },
            {
                "name": "Situating Palestine",
                "link": "https://situatingpalestine.nl/",
                "img": "watermelon.jpg"
            }
        ]
    },
    {
        name: "Rijksuniversiteit Groningen",
        tag: "RUG",
        description: "",
        display_description: false,
        dot_position: { x: 81, y: 12 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "Groningen Student Encampment",
                "link": "https://linktr.ee/encampment.rug",
                "img": "gse.jpg"
            },
            {
                "name": "Groningen for Palestine",
                "link": "https://x.com/YallaGroningen",
                "img": "gfp.jpg"
            }
        ]
    },
    {
        name: "Technische Universiteit Delft",
        tag: "TUD",
        description: "",
        display_description: false,
        dot_position: { x: 30.2, y: 54.3 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "Delft Student Intifada",
                "link": "https://www.instagram.com/delft_student_intifada/",
                "img": "dsi.png"
            },
            {
                "name": "End Fossil LU & TUD",
                "link": "https://www.instagram.com/endfossil.lu.tud/",
                "img": "EFLD.jpg"
            },
            {
                "name": "BK Scholars for Palestine",
                "link": "https://www.instagram.com/bkscholarsforpalestine/",
                "img": "bk4p.png"
            }
        ]
    },
    {
        name: "Technische Universiteit Eindhoven",
        tag: "TUe",
        description: "",
        display_description: false,
        dot_position: { x: 56.4, y: 74.5 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "University Rebellion Eindhoven",
                "link": "https://www.instagram.com/universityrebellion_tue/",
                "img": "URTUE.png"
            },
            {
                "name": "Eindhoven 4 Palestine",
                "link": "https://www.instagram.com/eindhoven4palestine/",
                "img": "eifp.jpg"
            }
        ]
    },
    {
        name: "Tilburg University",
        tag: "Til",
        description: "",
        display_description: false,
        dot_position: { x: 46.4, y: 69 },
        color: Categories.UNIVERSITY,
        groups : [
        ]
    },
    {
        name: "Maastricht University",
        tag: "UM",
        description: "",
        display_description: false,
        dot_position: { x: 61.5, y: 95 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "Free Palestine Maastricht",
                "link": "https://linktr.ee/freepalestine.maastricht",
                "img": "fpm.jpg"
            }
        ]
    },
    {
        name: "Universiteit Twente",
        tag: "UT",
        description: "",
        display_description: false,
        dot_position: { x: 87, y: 45 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "Enschede Students for Palestine",
                "link": "https://www.instagram.com/enschedestudentsforpalestine/",
                "img": "efp.png"
            }
        ]
    },
    {
        name: "Universiteit Utrecht",
        tag: "UU",
        description: "",
        display_description: false,
        dot_position: { x: 48, y: 51 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "End Fossil Utrecht",
                "link": "https://www.instagram.com/efo.utrecht/",
                "img": "EFU.jpg"
            },
            {
                "name": "Utrecht in Solidarity with Palestine",
                "link": "https://instagram.com/palestinesolidarity.usp",
                "img": "usp.png"
            },
            {
                "name": "Utrecht Student Encampment",
                "link": "https://instagram.com/encampment.uu",
                "img": "use.png"
            },
            {
                "name": "UU scholars and staff for Palestine",
                "link": "https://www.instagram.com/utrechtuni4palestine/",
                "img": "uus4p.png"
            }
        ]
    },
    {
        name: "Universiteit van Amsterdam",
        tag: "UvA",
        description: "",
        display_description: false,
        dot_position: { x: 41.5, y: 40.5 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "AU Free Palestine",
                "link": "https://linktr.ee/aufreepalestine",
                "img": "aufp.png"
            },
            {
                "name": "ORCA",
                "link": "https://linktr.ee/orcamsterdam",
                "img": "logo_orca.png"
            },
            {
                "name": "Mokum Kraakt",
                "link": "https://www.instagram.com/mokumkraakt",
                "img": "MK.jpg"
            },
            {
                "name": "Autonomous Student Struggle",
                "link": "https://www.instagram.com/autonomousstudentstruggle/",
                "img": "AUSS.jpg"
            },
            {
                "name": "SRP UvA",
                "link": "https://www.instagram.com/sjpamsterdam/",
                "img": "SJP.jpg"
            },
            {
                "name": "Activistenpartij UvA",
                "link": "https://www.instagram.com/activistenpartijuva",
                "img": "Activistenpartij_UvA.jpg"
            }
        ]
    },
    {
        name: "Vrije Universiteit Amsterdam",
        tag: "VU",
        description: "",
        display_description: false,
        dot_position: { x: 40.5, y: 42 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "The VU Coalition for Palestine",
                "link": "https://linktr.ee/vuforfalestine",
                "img": "vu4f.jpg"
            }
        ]
    },
    {
        name: "Wageningen University & Research",
        tag: "WUR",
        description: "",
        display_description: false,
        dot_position: { x: 60.5, y: 53.5 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "End Fossil Occupy WUR",
                "link": "https://www.instagram.com/endfossilwur/",
                "img": "EFWUR.jpg"
            },
            {
                "name": "Wageningen Student Encampment",
                "link": "https://linktr.ee/wageningenencampment",
                "img": "WSE.png"
            }
        ]
    },
    {
        name: "Hogeschool van Amsterdam",
        tag: "HvA",
        description: "",
        display_description: false,
        dot_position: { x: 43.5, y: 42 },
        color: Categories.UNIVERSITY,
        groups : [
        ]
    },
    {
        name: "Hogeschool Utrecht",
        tag: "HU",
        description: "",
        display_description: false,
        dot_position: { x: 50.5, y: 51.3 },
        color: Categories.UNIVERSITY,
        groups : [
        ]
    },
    {
        name: "Hogeschool Rotterdam",
        tag: "HR",
        description: "",
        display_description: false,
        dot_position: { x: 32.7, y: 58 },
        color: Categories.UNIVERSITY,
        groups : [
            {
                "name": "RUAS Staff for Palestine",
                "link": "https://www.instagram.com/ruasstaff4palestine/",
                "img": "ruas.jpg"
            },
            {
                "name": "HR students for Palestine",
                "link": "https://www.instagram.com/hrforpalestine/",
                "img": "hrs4p.jpg"
            }
        ]
    }
];


/**
 * @param {string} tag
 * @returns {AcademicInstitution | undefined}
 */
export const findInstitutionByTag = (tag) => {
    return academicInstitutions.find(institution => institution.tag === tag);
};

/**
 * @param {string} category
 * @returns {AcademicInstitution[]}
 */
export const filterInstitutionsByCategory = (category) => {
    return academicInstitutions.filter(institution => institution.color === Categories[category]);
};

