<template>
  <div class="house">
    <div class="midcontainer">

      <InteractiveMap msg="" @select-uni="selectUni" />
      <div class="blurbEnd">
        <WhyMap/>
        <References/>
      </div>

    </div>
  </div>

  <CustomFooter/>
</template>

<script>

import '@/core/assets/css/core_main.css';
// specific rules will override core ones
import '@/country_specific/assets/css/specific_main.css';


import InteractiveMap from '@/country_specific/components/InteractiveMap.vue'
import WhyMap from "@/country_specific/components/WhyMap.vue";
import CustomFooter from "@/core/components/CustomFooter.vue";
import References from '@/core/components/OrgReferences.vue';

export default {
  name: 'App',
  components: {
    WhyMap,
    References,
    CustomFooter,
    InteractiveMap
  },
  data() {
    return {
      uniNonSelected: true,
      shortUni: String,
      nameUni: String,
      width: document.documentElement.clientWidth
    }
  },
  methods: {
    selectUni(uni) {
      this.shortUni = uni.shortForm
      this.nameUni = uni.uni
      this.uniNonSelected = false
    },

  },
  mounted() {
    localStorage.removeItem("shortUni")
    localStorage.removeItem("nameUni")
    // window.addEventListener('resize', this.changeClass);
  }
}
</script>


<style></style>