<script setup>

</script>

<template>

  <h2>Why map the ties between Dutch Universities and Israel?</h2>
  <p>
    From late 2023 until now, in response to the ongoing <a
      href="https://www.ohchr.org/sites/default/files/documents/hrbodies/hrcouncil/sessions-regular/session55/advance-versions/a-hrc-55-73-auv.pdf"
      target="_blank" style="color: #2c2e31">genocide</a> in Palestine perpetrated by the state of Israel,
    students and staff of Dutch
    universities increased pressure on their institutions to cut ties with Israeli companies and universities
    complicit in this crime against humanity.
    Students and staff have been calling for the academic boycott of Israel for many years prior, because Israeli
    academic institutions have been complicit in the commission of <a
      href="https://www.amnesty.org/en/documents/mde15/5141/2022/en/" style="color: #2c2e31"
      target="_blank">apartheid</a>, <a href="https://www.alhaq.org/advocacy/20931.html" style="color: #2c2e31"
      target="_blank">colonization</a>, and <a href="https://news.un.org/en/story/2022/10/1129942"
      style="color: #2c2e31" target="_blank">occupation</a> against the Palestinian
    people for many decades.
    This website serves as an information source in support of their activities, and for anyone else interested in
    this issue.
  </p>
  <p>
    Based on a variety of sources, including the CORDIS-website with EU funded research projects, university
    websites, results from Freedom of Information requests and the work of Dr. Maya Wind, this website maps
    institutional ties that are current as of March 2025. It builds on research by Stop Wapenhandel, the Rights
    Forum, Students for Palestine and students and staff at various Dutch universities, and is a work in progress.
  </p>
  <h3>What does this overview show?</h3>
  <p>
    Apart from ties with Israeli entities - companies, universities, state bodies and research institutions - the
    overview includes long-term relations with major international military security companies that provide arms
    and technologies to the Israeli Defence Forces and/or are involved in ongoing cooperation with the Israeli
    arms industry.
  </p>
  <p>
    In line with the calls of the <a href="https://bdsmovement.net/academic-boycott#guidelines" target="_blank"
      style="color: #2c2e31">international BDS movement</a>, the overview focuses on institutional ties and does
    not include collaborations between individual academics. We mapped all ties between Dutch universities and
    Israeli academic institutions, since all Israeli academic institutions are documented and known to be actively
    complicit in the violation of Palestinian rights. This makes them subject to the <a style="color: #2c2e31"
      href="https://bdsmovement.net/academic-boycott" target="_blank">PACBI/BDS call for academic boycott</a>.
  </p>
  <p>
    We also included all ties of Dutch universities with Israeli companies, because many of them have been
    documented and known to be involved in military and security-related work and therefore subject to <a
      href="https://bdsmovement.net/economic-boycott" style="color: #2c2e31" target="_blank">the economic
      boycott</a>. Some companies listed are not obviously complicit in the violation of Palestinian rights, but
    all of them are part of an economy based in Palestinian dispossession. <b>We encourage further research into
      all ties and partners</b>.

    <!--In line with the <a style="color: #2c2e31" href="https://bdsmovement.net/academic-boycott"
      target="_blank">calls of the international BDS movement</a>, the overview focuses on institutional ties and
    does not include collaborations between individual academics. <b>Note that not all listed Israeli entities are
      involved in military or security-related work, or have the same level of complicity in the violation of
      Palestinian rights</b>. We document all ties with Israeli companies because the BDS movement calls for the
    <a href="https://bdsmovement.net/economic-boycott#tab2" style="color: #2c2e31" target="_blank">economic
      boycott of all Israeli products</a>. We map all ties with Israeli academic institutions because all of them
    are known to be actively complicit in the violation of Palestinian rights, and so long as they are are subject
    to the <a href="https://bdsmovement.net/academic-boycott#guidelines" target="_blank"
      style="color: #2c2e31">PACBI/BDS call for boycott</a>.-->
  </p>
  <p>
    One important type of relationship is missing from this overview: universities' investments,
    e.g. shares in companies. Such investments are made by separate university funds, which do
    not publish their portfolios.
  </p>

</template>

<style scoped></style>