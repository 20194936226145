<script setup>

</script>

<template>

  <div class="moveToMiddle">
    <button class="blurbbegin"
            onclick="window.open('http://complicitymap.eu/')"
            type="button" style="background-color: #f5c242;"><b>NEW</b>: See how the European Commission funds Israeli universities and companies</button>
    <button class="blurbbegin"
            onclick="window.open('https://www.democracynow.org/2024/3/15/maya_wind_towers_of_ivory_and')"
            type="button">Listen to Dr. Maya Wind: how Israeli universities are complicit in aggression against
      Palestinians</button>
    <button class="blurbbegin"
            onclick="window.open('https://stopwapenhandel.org/european-universities-cooperation-with-israel-in-eu-security-research-programs/')"
            type="button">See the work of Stop Wapenhandel on Israeli-academic ties</button>
    <button class="blurbbegin" onclick="window.open('https://rightsforum.org/tag/wob-verzoek/')"
            type="button">Read about The Rights Forum's Freedom of Information requests to Dutch universities</button>
    <button class="blurbbegin" onclick="window.open('https://academiccomplicity.be')"
            type="button">Check out our sister website for Belgian universities 🇧🇪 </button>
  </div>

</template>

<style scoped>

</style>